import { addQueryParamsToString } from '@monorepo/shared/lib/utils';
import {
	AcceptanceQuestion,
	AddConsumerCartRequest,
	AnswerQuestionsRequest,
	Cart,
	CartAcceptanceState,
	CartFromQueryParams,
	Contact,
	CreateConsumerCartRequest,
	CreatedConsumerCartResponse,
	IComposition,
	IConsumerContact,
	IGetAllCurrentInsurancesResponse,
	IGetAllHistoricalClaimsResponse,
	IGetClaimableProductsResponse,
	IGetCurrentInsurancesResponse,
	IGetObjectsResponse,
	IGetObjectsResponseTransformed,
	IGetTransferableProductsRequest,
	IGetTransferableProductsResponse,
	IObjectDetailsRequest,
	IObjectDetailsSpec,
	IObjectDetailsSpecs,
	IPremiumQuestionGroup,
	IProductQuestion,
	IProductStructure,
	ISaveCurrentInsurancesRequest,
	ISaveHistoricalClaimsRequest,
	ISaveHistoricalIllnessRequest,
	ISavePremiumQuestionsForObjectRequest,
	ISavePremiumQuestionsForObjectResponse,
	IStakeholder,
	IStakeholderRequest,
	IUpdateHistoricalClaimsRequest,
	IUpdateHistoricalIllnessRequest,
	PremiumQuestion,
	QuestionAnswer,
} from '@monorepo/types';

import { paths } from '@common/utils';
import { getGroupBasedOnQuestionGuid, getNextSequenceBasedOnReference } from '@funnel/hooks';
import { rootConsumerApi } from './../../root/root';

export const consumerCartApi = rootConsumerApi.injectEndpoints({
	endpoints: ({ query, mutation }) => ({
		// [POST] cart
		createConsumerCart: mutation<CreatedConsumerCartResponse, CreateConsumerCartRequest>({
			query(payload) {
				return {
					url: `/carts`,
					method: 'POST',
					body: payload,
				};
			},
		}),
		getConsumerCart: query<Cart, string>({
			query(cart_guid: string) {
				return {
					url: `/carts/${cart_guid}`,
					method: 'GET',
				};
			},

			providesTags: (result) => {
				if (result && result?.guid) {
					return [{ type: 'Cart', id: result?.guid }];
				}

				/* istanbul ignore next */
				return ['Cart'];
			},
		}),
		addObjectToCart: mutation<{ object_guid: string }, AddConsumerCartRequest>({
			query({ cartGuid, ...payload }) {
				return {
					url: `/carts/${cartGuid}`,
					method: 'PUT',
					body: payload,
				};
			},
			invalidatesTags: ['Objects'],
		}),
		// [GET] object details
		getObjects: query<IGetObjectsResponseTransformed, string>({
			query(cartGuid) {
				return {
					url: `/carts/${cartGuid}/objects`,
					method: 'GET',
				};
			},
			providesTags: ['Objects'],
			transformResponse: (response: IGetObjectsResponse) => {
				function transformSpecsToObj(specs: Array<IObjectDetailsSpec>) {
					return specs.reduce((acc: IObjectDetailsSpecs, spec: IObjectDetailsSpec) => {
						acc[spec.reference] = spec.answer;
						return acc;
					}, {});
				}

				return [...response].map((object) => ({
					guid: object.guid,
					reference: object.reference,
					stakeholders: object.stakeholders,
					specs: transformSpecsToObj(object.specs),
				}));
			},
		}),
		// [PUT] objects
		/**
		 * @deprecated
		 */
		saveObjectDetails: mutation<unknown, IObjectDetailsRequest & { cartGuid: string; objectGuid: string }>({
			query({ cartGuid, objectGuid, ...payload }) {
				return {
					url: `/carts/${cartGuid}/objects/${objectGuid}`,
					method: 'PUT',
					body: payload,
				};
			},
			invalidatesTags: ['Objects'],
		}),
		// [GET] Stakeholders
		getStakeholdersForObject: query<IStakeholder[], { cartGuid: string; objectGuid: string }>({
			query({ cartGuid, objectGuid }) {
				return {
					url: `/carts/${cartGuid}/objects/${objectGuid}/stakeholders`,
					method: 'GET',
				};
			},
			providesTags: ['Stakeholders', 'Objects'],
		}),
		// [PUT] Stakeholders
		saveStakeholderForObject: mutation<
			unknown,
			{ stakeholders: IStakeholderRequest; cartGuid: string; objectGuid: string }
		>({
			query({ cartGuid, objectGuid, ...payload }) {
				return {
					url: `/carts/${cartGuid}/objects/${objectGuid}/stakeholders`,
					method: 'POST',
					body: payload,
				};
			},
			invalidatesTags: ['Stakeholders'],
		}),
		// [GET] Premium Questions
		getPremiumQuestionsForObject: query<
			{
				reference: string;
				questions: PremiumQuestion[];
				title: string;
				replacements: Array<{
					replace_this: string;
					replace_with: string | null;
				}>;
			}[],
			{ cartGuid: string; objectGuid: string }
		>({
			query({ cartGuid, objectGuid }) {
				return {
					url: `/carts/${cartGuid}/objects/${objectGuid}/premium-questions`,
					method: 'GET',
				};
			},
			providesTags: ['PremiumQuestions', 'Objects'],
		}),
		// [PUT] Premium Questions
		savePremiumQuestionsForObject: mutation<
			ISavePremiumQuestionsForObjectResponse,
			ISavePremiumQuestionsForObjectRequest
		>({
			query({ cartGuid, objectGuid, questions, productGuid }) {
				return {
					url: `/carts/${cartGuid}/objects/${objectGuid}/premium-questions`,
					method: 'PUT',
					body: { questions, product_guid: productGuid },
				};
			},
			transformResponse: (response: ISavePremiumQuestionsForObjectResponse, _, arg) => {
				const currentPremiumQuestionGroup = getGroupBasedOnQuestionGuid({
					groups: response.premium_questions,
					questions: [...arg.questions].map((q) => q.guid),
				});

				const queryParams = { cartGuid: arg.cartGuid, objectGuid: arg.objectGuid };

				if ((!currentPremiumQuestionGroup || !response.premium_questions) && arg.fallbackUrl) {
					return { ...response, nextPage: addQueryParamsToString(arg.fallbackUrl, queryParams) };
				}

				const nextReference = getNextSequenceBasedOnReference<IPremiumQuestionGroup>({
					objects: response.premium_questions,
					currentReference: currentPremiumQuestionGroup?.reference,
				});

				if (!nextReference) {
					// TODO: When a request contact page is added, add that to here
					return { ...response, nextPage: arg.continueUrl ? addQueryParamsToString(arg.continueUrl, queryParams) : '' };
				}

				return {
					...response,
					nextPage: paths.consumerFunnel.premiumQuestions.dynamic(addQueryParamsToString(nextReference, queryParams)),
				};
			},
			invalidatesTags: ['PremiumQuestions', 'Compositions', 'ProductStructure', 'ProductQuestions'],
		}),
		// [GET] Compositions
		getCompositionsForObject: query<IComposition[], { cartGuid: string; objectGuid: string }>({
			query({ cartGuid, objectGuid }) {
				return {
					url: `/carts/${cartGuid}/objects/${objectGuid}/compositions`,
					method: 'GET',
				};
			},
			providesTags: ['Compositions', 'Objects'],
		}),
		// [PUT] Compositions
		saveCompositionForObject: mutation<unknown, { cartGuid: string; objectGuid: string; compositionGuid: string }>({
			query({ cartGuid, objectGuid, compositionGuid }) {
				return {
					url: `/carts/${cartGuid}/objects/${objectGuid}/compositions/${compositionGuid}`,
					method: 'PUT',
				};
			},

			invalidatesTags: ['Compositions', 'Objects'],
		}),
		getProductStructureForCart: query<IProductStructure[], { cartGuid: string }>({
			query({ cartGuid }) {
				return {
					url: `/carts/${cartGuid}/product-structure`,
					method: 'GET',
				};
			},
			providesTags: ['ProductStructure'],
		}),
		// [GET] product-structure for object
		getProductStructureForObject: query<IProductStructure[], { cartGuid: string; objectGuid: string }>({
			query({ cartGuid, objectGuid }) {
				return {
					url: `/carts/${cartGuid}/objects/${objectGuid}/product-structure`,
					method: 'GET',
				};
			},
			providesTags: ['ProductStructure'],
		}),
		getProductQuestionsForCart: query<IProductQuestion[], { cartGuid: string }>({
			query({ cartGuid }) {
				return {
					url: `/carts/${cartGuid}/objects`,
					method: 'GET',
				};
			},
			providesTags: ['ProductQuestions'],
		}),
		// [GET] Product questions
		getProductQuestionsForObject: query<IProductQuestion[], { cartGuid: string; objectGuid: string }>({
			query({ cartGuid, objectGuid }) {
				return {
					url: `/carts/${cartGuid}/objects/${objectGuid}/product-questions`,
					method: 'GET',
				};
			},
			providesTags: ['ProductQuestions', 'Objects'],
		}),
		// [PUT] Product questions
		saveProductQuestionsForObject: mutation<
			{ product_questions: IProductQuestion[]; product_structure: IProductStructure[]; validation_state: boolean },
			{ cartGuid: string; objectGuid: string; productGuid: string; questions: QuestionAnswer[] }
		>({
			query({ cartGuid, objectGuid, productGuid, questions }) {
				/* istanbul ignore next */
				return {
					url: `/carts/${cartGuid}/objects/${objectGuid}/products/${productGuid}`,
					method: 'PUT',
					body: { questions },
				};
			},
			invalidatesTags: ['Compositions', 'ProductStructure', 'ProductQuestions', 'Objects'],
		}),
		// [GET] Acceptance Questions
		getConsumerAcceptanceQuestions: query<AcceptanceQuestion[], string>({
			query(cart_guid: string) {
				return {
					url: `/carts/${cart_guid}/acceptance-questions`,
					method: 'GET',
				};
			},
			providesTags: (result) => {
				/* istanbul ignore next */
				if (result) {
					return result.map(({ guid }) => ({ type: 'AcceptanceQuestion', id: guid }));
				}

				/* istanbul ignore next */
				return ['AcceptanceQuestion'];
			},
		}),
		generateConsumerAcceptanceQuestions: mutation<void, { cart_guid: string }>({
			query({ cart_guid }) {
				return {
					url: `/carts/${cart_guid}/acceptance-questions`,
					method: 'POST',
					body: {},
				};
			},
			invalidatesTags: ['AcceptanceQuestion', 'AcceptanceState'],
		}),
		answerConsumerAcceptanceQuestions: mutation<void, AnswerQuestionsRequest>({
			query({ cart_guid, questions }) {
				return {
					url: `/carts/${cart_guid}/acceptance-questions`,
					method: 'PUT',
					body: { questions },
				};
			},
			invalidatesTags: (_result, _error, { cart_guid }) => ['AcceptanceState', { type: 'Cart', id: cart_guid }],
			async onQueryStarted({ cart_guid, questions: patchQuestions }, { dispatch, queryFulfilled }) {
				/* istanbul ignore next */
				const patchResult = dispatch(
					consumerCartApi.util.updateQueryData('getConsumerAcceptanceQuestions', cart_guid, (existingQuestions) => {
						if (!existingQuestions) return [];

						return existingQuestions.map((question) => {
							const patchQuestion = patchQuestions.find((pq) => pq.guid === question.guid);
							if (patchQuestion) {
								return {
									...question,
									is_answered: true,
									answer_readable: patchQuestion.answer === 'J' ? 'Ja' : 'Nee',
									answer_multiple_choice: {
										key: patchQuestion.answer === 'J' ? 'J' : 'N',
										value: patchQuestion.answer === 'J' ? 'Ja' : 'Nee',
									},
								};
							}
							return question;
						});
					})
				);

				/* istanbul ignore next */
				try {
					await queryFulfilled;
				} catch {
					patchResult.undo();
				}
			},
		}),
		getTransferableProducts: query<IGetTransferableProductsResponse, IGetTransferableProductsRequest>({
			query({ cartGuid, objectGuid }) {
				return {
					url: `/carts/${cartGuid}/objects/${objectGuid}/transferable-products`,
					method: 'GET',
				};
			},
		}),
		// [GET] All Current insurance
		getAllCurrentInsurance: query<IGetAllCurrentInsurancesResponse, string>({
			query(cartGuid) {
				return {
					url: `/carts/${cartGuid}/current-insurances`,
					method: 'GET',
				};
			},
			providesTags: ['CurrentInsurances'],
		}),

		// [GET] Current insurance
		getCurrentInsurance: query<IGetCurrentInsurancesResponse, { cartGuid: string; objectGuid: string }>({
			query({ cartGuid, objectGuid }) {
				return {
					url: `/carts/${cartGuid}/objects/${objectGuid}/current-insurances`,
					method: 'GET',
				};
			},
			providesTags: ['CurrentInsurances'],
		}),

		// [POST] Current insurance
		saveCurrentInsurance: mutation<void, ISaveCurrentInsurancesRequest>({
			query({ cartGuid, objectGuid, ...rest }) {
				return {
					url: `/carts/${cartGuid}/objects/${objectGuid}/current-insurances`,
					method: 'POST',
					body: rest,
				};
			},
			invalidatesTags: ['CurrentInsurances'],
		}),
		// [PUT] Current insurance
		updateCurrentInsurance: mutation<
			void,
			{
				cartGuid: string;
				objectGuid: string;
				itcGuid: string;
				product_code: string;
				insurer_code: string;
				policy_identifier: string;
				product_guid: string;
			}
		>({
			query({ cartGuid, objectGuid, itcGuid, ...rest }) {
				return {
					url: `/carts/${cartGuid}/objects/${objectGuid}/current-insurances/${itcGuid}`,
					method: 'PUT',
					body: rest,
				};
			},
			invalidatesTags: ['CurrentInsurances'],
		}),
		// [DELETE] Current insurance
		deleteCurrentInsurance: mutation<void, { cartGuid: string; objectGuid: string; insuranceGuid: string }>({
			query({ cartGuid, objectGuid, insuranceGuid }) {
				return {
					url: `/carts/${cartGuid}/objects/${objectGuid}/current-insurances/${insuranceGuid}`,
					method: 'DELETE',
				};
			},
			invalidatesTags: ['CurrentInsurances'],
		}),
		// [DELETE] Current insurance
		deleteAllCurrentInsurance: mutation<void, string>({
			query(cartGuid) {
				return {
					url: `/carts/${cartGuid}/current-insurances/`,
					method: 'DELETE',
				};
			},
			invalidatesTags: ['CurrentInsurances'],
		}),
		getConsumerContact: query<IConsumerContact, string>({
			query(cartGuid) {
				return {
					url: `/carts/${cartGuid}/contact`,
					method: 'GET',
				};
			},
			providesTags: ['Contact'],
		}),
		// [PUT] Contact
		setConsumerContact: mutation<
			void,
			{
				cart_guid: string;
				date_of_birth: string;
				address?: {
					street: string;
					house_number: number;
					house_number_appendix?: string;
					postal_code: string;
					city: string;
					country?: string;
				};
			} & Contact
		>({
			query({ cart_guid, ...rest }) {
				return {
					url: `/carts/${cart_guid}/contact`,
					method: 'PUT',
					body: rest,
				};
			},
			invalidatesTags: ['Contact'],
		}),
		getConsumerAcceptanceState: query<CartAcceptanceState, string>({
			query(cart_guid: string) {
				return {
					url: `/carts/${cart_guid}/acceptance-state`,
					method: 'GET',
				};
			},
			providesTags: ['AcceptanceState'],
		}),
		consumerCheckout: mutation<
			{ existing_customer: boolean },
			{ cart_guid: string; effective_date: string; terms_agreement: boolean; iban?: string }
		>({
			query({ cart_guid, ...rest }) {
				return {
					url: `/carts/${cart_guid}/checkout`,
					method: 'POST',
					body: rest,
				};
			},
			invalidatesTags: ['Cart'],
		}),

		getClaimableProducts: query<IGetClaimableProductsResponse, { cartGuid: string; objectGuid: string }>({
			query({ cartGuid, objectGuid }) {
				return {
					url: `/carts/${cartGuid}/objects/${objectGuid}/claimable-products`,
					method: 'GET',
				};
			},
		}),

		// [GET] All Historical claims
		getAllHistoricalClaims: query<IGetAllHistoricalClaimsResponse, string>({
			query(cartGuid) {
				return {
					url: `/carts/${cartGuid}/historical-claims`,
					method: 'GET',
				};
			},
			providesTags: ['HistoricalClaims'],
		}),
		deleteAllHistoricalClaims: mutation<void, string>({
			query(cartGuid) {
				return {
					url: `/carts/${cartGuid}/historical-claims`,
					method: 'DELETE',
				};
			},
			invalidatesTags: ['HistoricalClaims'],
		}),
		// [POST] Historical Claims
		saveHistoricalClaim: mutation<void, ISaveHistoricalClaimsRequest | ISaveHistoricalIllnessRequest>({
			query({ cartGuid, objectGuid, ...rest }) {
				return {
					url: `/carts/${cartGuid}/objects/${objectGuid}/historical-claims`,
					method: 'POST',
					body: rest,
				};
			},
			invalidatesTags: ['HistoricalClaims'],
		}),
		// [PUT] Historical Claims
		updateHistoricalClaim: mutation<void, IUpdateHistoricalClaimsRequest | IUpdateHistoricalIllnessRequest>({
			query({ cartGuid, objectGuid, historicalClaimGuid, ...rest }) {
				return {
					url: `/carts/${cartGuid}/objects/${objectGuid}/historical-claims/${historicalClaimGuid}`,
					method: 'PUT',
					body: rest,
				};
			},
			invalidatesTags: ['HistoricalClaims'],
		}),
		// [DELETE] Historical Claims
		deleteHistoricalClaim: mutation<void, { cartGuid: string; objectGuid: string; historicalClaimGuid: string }>({
			query({ cartGuid, objectGuid, historicalClaimGuid }) {
				return {
					url: `/carts/${cartGuid}/objects/${objectGuid}/historical-claims/${historicalClaimGuid}`,
					method: 'DELETE',
				};
			},
			invalidatesTags: ['HistoricalClaims'],
		}),
		cartFromQueryParams: mutation<
			{ cart_guid: string; premiums_calculated: boolean; object_guid: string },
			{ payload: CartFromQueryParams }
		>({
			query({ payload }) {
				return {
					url: '/partners/compose',
					method: 'POST',
					body: payload,
				};
			},
		}),
	}),
	overrideExisting: true,
});

export const {
	useCreateConsumerCartMutation,
	useGetConsumerCartQuery,
	useLazyGetConsumerCartQuery,
	useAddObjectToCartMutation,
	useGetObjectsQuery,
	useLazyGetObjectsQuery,
	useGetStakeholdersForObjectQuery,
	useSaveStakeholderForObjectMutation,
	useGetPremiumQuestionsForObjectQuery,
	useSavePremiumQuestionsForObjectMutation,
	useGetCompositionsForObjectQuery,
	useSaveCompositionForObjectMutation,
	useGetProductStructureForObjectQuery,
	useLazyGetProductStructureForObjectQuery,
	useGetProductQuestionsForObjectQuery,
	useSaveProductQuestionsForObjectMutation,
	useGetConsumerAcceptanceQuestionsQuery,
	useLazyGetConsumerAcceptanceQuestionsQuery,
	useGenerateConsumerAcceptanceQuestionsMutation,
	useAnswerConsumerAcceptanceQuestionsMutation,
	useGetTransferableProductsQuery,
	useSaveCurrentInsuranceMutation,
	useUpdateCurrentInsuranceMutation,
	useGetCurrentInsuranceQuery,
	useDeleteCurrentInsuranceMutation,
	useGetConsumerContactQuery,
	useSetConsumerContactMutation,
	useGetConsumerAcceptanceStateQuery,
	useConsumerCheckoutMutation,
	useGetProductStructureForCartQuery,
	useGetProductQuestionsForCartQuery,
	useGetAllCurrentInsuranceQuery,
	useGetAllHistoricalClaimsQuery,
	useGetClaimableProductsQuery,
	useSaveHistoricalClaimMutation,
	useDeleteHistoricalClaimMutation,
	useUpdateHistoricalClaimMutation,
	useDeleteAllCurrentInsuranceMutation,
	useDeleteAllHistoricalClaimsMutation,
	useCartFromQueryParamsMutation,
} = consumerCartApi;

export const {
	createConsumerCart,
	getObjects,
	getPremiumQuestionsForObject,
	getStakeholdersForObject,
	saveStakeholderForObject,
	getCurrentInsurance,
	saveObjectDetails,
	savePremiumQuestionsForObject,
} = consumerCartApi.endpoints;
