import {
	BillingUnits,
	CalculationState,
	Cart,
	CreateCartRequest,
	CreateConsumerCartRequest,
	CreatedCartResponse,
	CreatePortalCartRequest,
	ToggleDeductibleRequest,
	ToggleDeductibleResponse,
} from '@monorepo/types';

import { rootApi } from '../../../../common/store/root/root.api';

export const cartApi = rootApi.injectEndpoints({
	endpoints: ({ query, mutation }) => ({
		getCart: query<Cart, string>({
			query(cart_guid: string) {
				return {
					url: `/carts/${cart_guid}`,
					method: 'GET',
				};
			},

			providesTags: (result) => {
				if (result && result?.guid) {
					return [{ type: 'Cart', id: result?.guid }];
				}

				/* istanbul ignore next */
				return ['Cart'];
			},
		}),
		getCartCalculationState: query<CalculationState, string>({
			query(cart_guid: string) {
				return {
					url: `/carts/${cart_guid}/calculation-state`,
					method: 'GET',
				};
			},
			providesTags: ['CalculationState'],
		}),
		createCart: mutation<CreatedCartResponse, CreatePortalCartRequest | CreateCartRequest | CreateConsumerCartRequest>({
			query({ at_gd, ...rest }) {
				return {
					url: at_gd ? `/carts?at_gd=${at_gd}` : `/carts`,
					method: 'POST',
					body: rest,
				};
			},
		}),
		toggleBillingUnit: mutation<void, { cartGuid: string; billing_unit_code: BillingUnits }>({
			query({ cartGuid, ...rest }) {
				return {
					url: `/carts/${cartGuid}/billing`,
					method: 'PUT',
					body: rest,
				};
			},
			invalidatesTags: ['Cart', 'Bundles', 'Calculations'],
		}),
		createCartBypass: mutation<CreatedCartResponse, CreatePortalCartRequest | CreateCartRequest>({
			query(payload) {
				return {
					url: `/carts/kvk-bypass`,
					method: 'POST',
					body: payload,
				};
			},
		}),
		toggleDeductible: mutation<ToggleDeductibleResponse, ToggleDeductibleRequest>({
			query({ cart_guid, deductible }) {
				return {
					url: `/carts/${cart_guid}/deductible`,
					method: 'PUT',
					body: { deductible },
				};
			},
			invalidatesTags: ['Cart', 'Bundles', 'Calculations'],
		}),
		approveInsuranceAfterReview: mutation<void, { cart_guid: string; iban: string; effective_date: string }>({
			query({ cart_guid, ...rest }) {
				return {
					url: `/carts/${cart_guid}/approval`,
					method: 'POST',
					body: rest,
				};
			},
			invalidatesTags: ['Approval'],
		}),
	}),
	overrideExisting: true,
});
export const {
	useCreateCartMutation,
	useGetCartQuery,
	useLazyGetCartQuery,
	useGetCartCalculationStateQuery,
	useCreateCartBypassMutation,
	useToggleBillingUnitMutation,
	useToggleDeductibleMutation,
	useApproveInsuranceAfterReviewMutation,
} = cartApi;

export const { getCart } = cartApi.endpoints;
